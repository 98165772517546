import React from 'react'
import RequestButton from '../RequestButton'
import FeaturesAndBenefits from '../FeaturesAndBenefits'
import Footnotes from '../Footnotes'
import './styles.scss'

const ProductOverview = ({ product }) => {

  return (
    <div>
      <h2 className="features-mobile">Features and Benefits</h2>
      <FeaturesAndBenefits data={product.featuresAndBenefits} customLink={product.hierarchyNode.custom_link} />
      <Footnotes footnotes={product.footnotes} />
      {
        product.hierarchyNode.sales_team && product.hierarchyNode.sales_team !== "Unassigned" &&
        <div data-testid="request-button" className="mt-5">
          <RequestButton node={product.hierarchyNode} />
        </div>
      }
    </div>
  )
}

export default ProductOverview